import React from 'react'

// Components
import Card from 'components/elements/Card'

// Third Party
import styled from 'styled-components'

interface UspVerticalProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledUspVertical = styled.section`
  gap: 22px;
`

const UspVertical: React.FC<UspVerticalProps> = ({ fields }) => (
  <StyledUspVertical className="d-flex flex-column align-items-center">
    {fields.usps?.map((usp, index) => (
      <div key={index}>
        <Card vertical fields={usp} />
      </div>
    ))}
  </StyledUspVertical>
)

export default UspVertical
