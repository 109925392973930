import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface CardProps {
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpPage_Flexcontent_Flex_Components_usps>
  vertical?: boolean
}

const StyledCard = styled.div<{ vertical: boolean }>`
  ${({ vertical }) =>
    vertical
      ? css`
          width: 320px;
          padding: 25px 35px 25px 35px;
          border-radius: 10px;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        `
      : css`
          background-color: ${({ theme }) => theme.color.light};
          border-radius: 10px;
          transition: all 0.3s ease-in-out;
          height: 100%;
          padding: 10px 10px;

          @media (max-width: 767px) {
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
          }

          @media (min-width: 768px) {
            padding: 15px 20px;
          }

          @media (min-width: 992px) {
            padding: 25px 20px 25px 30px;
          }

          &:hover {
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
          }
        `}
`

const CardBody = styled.div<{ vertical: boolean }>`
  ${({ vertical }) =>
    vertical
      ? css`
          display: inline-flex;
          gap: 25px;
        `
      : css`
          display: inline-flex;
          gap: 15px;
          @media (min-width: 576px) {
            flex-direction: column;
          }
        `}
`

const CardIcon = styled.div<{ vertical: boolean }>`
  ${({ vertical }) =>
    vertical
      ? css`
          @media (max-width: 575.98px) {
            img,
            svg {
              width: 35px;
              height: 35px;
            }
          }
          @media (min-width: 576px) {
            img,
            svg {
              width: 72px;
              height: 72px;
            }
          }
        `
      : css`
          img,
          svg {
            width: 60px;
            height: 40px;
          }

          @media (min-width: 768px) {
            img,
            svg {
              width: 40px;
              height: auto;
            }
          }

          @media (min-width: 991px) {
            img,
            svg {
              width: 100%;
              height: auto;
            }
          }
        `}
`

const CardText = styled.div<{ vertical: boolean }>`
  ${({ vertical }) =>
    vertical
      ? css`
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;

            & br {
              display: none;
            }
          }

          @media (min-width: 768px) {
            font-size: 18px;
            line-height: 18px;
          }

          @media (min-width: 991px) {
            font-size: 20px;
            line-height: 22px;
          }
        `
      : css`
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
            text-align: center;

            & br {
              display: none;
            }
          }

          /* @media (min-width: 768px) {
            font-size: 16px;
            line-height: 18px;
          }

          @media (min-width: 991px) {
            font-size: 20px;
            line-height: 22px;
          } */
        `}
`

const Card: React.FC<CardProps> = ({ fields, vertical = false }) => (
  <StyledCard vertical={vertical}>
    <CardBody vertical={vertical} className="align-items-center">
      <CardIcon vertical={vertical}>
        <Plaatjie image={fields?.icon} alt="" />
      </CardIcon>
      <CardText vertical={vertical}>
        <ParseContent content={fields?.description || ''} />
      </CardText>
    </CardBody>
  </StyledCard>
)

export default Card
